import React from "react";
import textStyles from "../styles/text.module.scss";
import classNames from 'classnames';

const FAQuestion = ({ children, question }) => (
  <>
    <div className={classNames(textStyles.title, textStyles.leftAlign, textStyles.question)}>
      { question }
    </div>
    <div className={ classNames(textStyles.leftAlign, textStyles.body, textStyles.questiontext) }>
      { children }
    </div>
  </>
);

export default FAQuestion;