import React from "react";
import { Link } from "gatsby";
import { backLink } from '../styles/button.module.scss';

const BackToIndex = ({ buttonText }) => (
  <div className={backLink}>
    <Link to='/'>{ buttonText }</Link>
  </div>
);

export default BackToIndex;