import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import faqStyles from "../styles/faq.module.scss"
import textStyles from "../styles/text.module.scss"
import quickStartStyles from '../styles/quick-start.module.scss';
import classNames from 'classnames';
import laptop2PhonesSvg from '../images/laptop-2-phones.svg';
import videoCallSvg from '../images/video-call.svg';
import { Link } from "gatsby"
import FAQuestion from "../components/faquestion"
import BackToIndex from "../components/back-to-index"

const FAQPage = ({ location }) => {
  return (
    <Layout>
      <SEO title="FAQs" />
      <div className={classNames(textStyles.title, textStyles.big)}>
        Frequently Asked <span className={textStyles.highlight}>Questions</span>
      </div>

      <FAQuestion question={'I’ve joined a game. Why won’t it start?'}>
        You need a minimum of 2 players to play a game. As soon as the 2nd player joins, a countdown starts before the next game begins.
      </FAQuestion>

      <FAQuestion question={'What are the minimum requirements to run Piing Party?'}>
        <p>If you're streaming the game with friends and family in different locations, you’ll need a modern fast laptop or desktop, running an up to date version of <a href='https://www.google.co.uk/chrome/' target={'_blank'} rel={'noopener noreferrer'}>Google Chrome</a> and a fast internet connection. </p>
        
        <p>To give your participants the best possible experience, quit all other apps except your video chat software and Chrome and close every tab in Chrome apart from the one running Piing Party.</p>
      </FAQuestion>

      <FAQuestion question={'Why is my game running slow for friends in different locations?'}>
        <p>You need a fast computer with a fast internet connection to get the best possible screen share to your friends. You can help your video chat software share your screen by quitting all other applications, and closing all other tabs in your browser. If everyone else in your household is also simultaneously running separate video chat calls, or downloading movies, this probably won’t help!</p>

        <p>For advice on optimizing screen sharing on Zoom check out their <a href='https://support.zoom.us/hc/en-us/articles/202954249-Optimizing-a-Shared-Video-Clip-in-Full-Screen' target={'_blank'} rel={'noopener noreferrer'}>support article</a>.</p>

        <p>If you’re still having trouble, ask the person with the fastest computer on the call to run the game from their computer instead of you (send them the URL for the Big Screen, and ask them to share their screen).</p>

      </FAQuestion>

      <FAQuestion question={'Is Piing Party available for corporate events?'}>
        <p>Piing Party is Piing's response to the Covid-19 crisis, acting as a new way to bring friends and family together when isolated in different locations. You can use it at home, with remote family and friends, with colleagues to liven up a meeting, or just as something to do over a Friday afternoon social.</p>

        <p>If you’re looking for more, head over to <a href='https://piing.events' target={'_blank'} rel={'noopener noreferrer'}>piing.events</a> where we can provide: 
          <ul>
            <li>Piing games with your logo</li>
            <li>Reskinned games to fit your brand</li>
            <li>Quiiz - a white label live stream quiz platform for 50 to 500,000 participants</li>
          </ul>
        </p>
      </FAQuestion>

      <FAQuestion question={'Something went wrong. How do I get in touch with you?'}>
        <a href='mailto:hello@piing.events?subject=Please help me with Piing Party!'>Send us an email</a> to hello@piing.events and we'll do our best to help.
      </FAQuestion>

      <BackToIndex buttonText={'← Home'} />
    </Layout>
  )
}

export default FAQPage